<template>
  <div id="videoSearchSet">
    <el-descriptions class="margin-top" :column="1" size="mini" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          播放器类型
        </template>
        analysis:视频解析, cPlayer: 外置播放器, inside: 内置播放器, cronTv: 资源内指定播放器
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          爬虫ID
        </template>
        0: 内部资源, this: 当前资源, outside: 获取内部资源
      </el-descriptions-item>
    </el-descriptions>
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="13">
        <el-button @click="addVideoResConfig">增加资源</el-button>
        <!--        <el-button @click="delVideoResConfig">保存全部资源</el-button>-->
        <el-table
            :data="tableData"
            border
            highlight-current-row
            @current-change="examine"
            style="width: 100%">
          <el-table-column label="别称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.resName"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="源站">
            <template slot-scope="scope">
              <el-input v-model="scope.row.resSource"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="播放器类型(analysis:解析)">
            <template slot-scope="scope">
              <el-input v-model="scope.row.resPlayType"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="爬虫ID">
            <template slot-scope="scope">
              <el-input v-model="scope.row.resSpiderId"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="danger"
                  @click="delVideoResConfig(scope.$index, scope.row)">删除
              </el-button>
              <el-button
                  v-if="scope.row.id != 0"
                  size="mini"
                  type="warning"
                  @click="updVideoFieldConfig(scope.$index, scope.row)">修改
              </el-button>
              <el-button v-else
                         size="mini"
                         type="primary"
                         @click="insVideoFieldConfig(scope.$index, scope.row)">新增
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="10">
        <el-button @click="addVideoFieldConfig">增加字段</el-button>
        <el-table
            :data="videoResCfg.resTypeJump"
            border
            style="width: 100%">
          <el-table-column label="字段名(else:没匹配到使用)">
            <template slot-scope="scope">
              <el-input v-model="scope.row.fieldName"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="字段值">
            <template slot-scope="scope">
              <el-input v-model="scope.row.fieldValue"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="爬虫ID(this:使用父Id)">
            <template slot-scope="scope">
              <el-input v-model="scope.row.fieldSpiderId"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="danger"
                  @click="delVideoFieldConfig(scope.$index, scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TvAsk from "@/request/expand/tv/index";

export default {
  name: "VideoSearchSet",
  data() {
    return {
      tvAsk: new TvAsk(),
      radio: '1',
      tableData: [],
      videoResCfg: {
        id: 0,
        resName: "别称",
        resSource: "腾讯视频",
        resSpiderId: 140,
        resTypeJump: [
          {
            fieldName: 'type',
            fieldValue: '电影',
            fieldSpiderId: '141'
          }
        ]
      },
    }
  }
  ,
  created() {
    this.tvAsk.getTvResConfig().then(res => {
      if (res.code == 'success') {
        this.tableData = res.msg
      }
    })
  },
  mounted() {
  },
  methods: {
    /**
     * 删除字段
     */
    delVideoFieldConfig(index, row) {
      this.videoResCfg.resTypeJump.splice(index, 1);
    },
    /**
     * 新增字段
     */
    addVideoFieldConfig() {
      this.videoResCfg.resTypeJump.push(
          {
            fieldName: '',
            fieldValue: '',
            fieldSpiderId: ''
          }
      )
    },
    updVideoFieldConfig(index, row) {
      this.$confirm('请确认操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tvAsk.updTvResConfig(row).then(res => {
          this.$message({
            showClose: true,
            type: 'success',
            message: '修改成功!'
          });
        }).catch(e => {
          this.$message.error('操作异常');
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          showClose: true,
          message: '已取消删除'
        });
      })
    },
    insVideoFieldConfig(index, row) {
      this.$confirm('请确认操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tvAsk.insTvResConfig(row).then(res => {
          this.$message({
            type: 'success',
            showClose: true,
            message: '新增成功!'
          });
        }).catch(e => {
          this.$message.error('操作异常');
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          showClose: true,
          message: '已取消删除'
        });
      })
    },
    /**
     * 删除资源
     */
    delVideoResConfig(index, item) {
      this.$confirm('请确认操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tvAsk.delTvResConfig(item.id).then(res => {
          this.tableData.splice(index, 1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(e => {
          this.$message.error('操作异常');
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    /**
     * 新增资源
     */
    addVideoResConfig() {
      this.tableData.push(
          {
            id: 0,
            resName: "",
            resSource: "",
            resSpiderId: "",
            resPlayType: "",
            resTypeJump: [
              {
                fieldName: '',
                fieldValue: '',
                fieldSpiderId: ''
              }
            ]
          }
      )
    },
    /**
     * 选中配置字段显示
     * @param row
     */
    examine(row) {
      this.videoResCfg = row
    }
  }
}
</script>

<style scoped>
#videoSearchSet {
  text-align: left;
}

ul {
  padding: 0;
  text-align: left;
  height: 400px;
  overflow: auto
}

li {
  list-style: none;
  padding: 5px 0;
  border-bottom: 1px solid;
}
</style>