import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class automaticSpider {
    constructor() {
        this.http = new HttpHandler();
    }

    //获取数据
    getTimePostData(data) {
        const url = '/api/tree/getTreeRoot'
        return this.http.get(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    //获取数据
    getTreeAll() {
        const url = '/api/tree/getTreeAll'
        return this.http.get(url, null, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 视频资源搜索配置
     * @returns {Promise<AxiosResponse<any>>}
     */
    getTvResConfig() {
        const url = '/api/tvGet/getTvResConfig'
        return this.http.get(url, null, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 新增视频资源搜索配置
     * @returns {Promise<AxiosResponse<any>>}
     */
    insTvResConfig(data) {
        const url = '/api/tvGet/insTvResConfig'
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 删除视频资源搜索配置
     * @returns {Promise<AxiosResponse<any>>}
     */
    delTvResConfig(id) {
        const url = `/api/tvGet/delTvResConfig?id=${id}`
        return this.http.delete(url, null, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 修改配置
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    updTvResConfig(data) {
        const url = '/api/tvGet/updTvResConfig'
        return this.http.put(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }


}
